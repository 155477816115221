@import './styles/theme';
@import './styles/layout';
@import './styles/material';

/* ROOT */
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100vh;
  @media only screen and (max-width: $tablet) {
    font-size: 14px;
  }
  @media only screen and (max-width: $mobile) {
    font-size: 12px;
  }
}

/* SCROLLBAR */
//Chrome, Edge, Safari
*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);        /* color of the tracking area */
}
*::-webkit-scrollbar-thumb {
  background-color: var(--midground); /* color of the scroll thumb */
  border: 4px solid rgba(0,0,0,0);  /* creates padding around scroll thumb */
}
//Firefox
* {
  scrollbar-color: var(--midground) rgba(0,0,0,0);   /* scroll thumb and track */
}

/* NOTIFICATION CLASSES */
.notify {
  &-success > div {
    background-color: var(--green) !important;
  }
  &-success simple-snack-bar > div {
    color: white !important;
  }
  &-error > div {
    background-color: var(--warn) !important;
  }
  &-error simple-snack-bar > div {
    color: white !important;
  }
}

/* GLOBAL STYLES */
.logo {
  display: inline-block;
  @extend .pointer;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}
.error {
  padding: 1rem 0;
  @extend .w-100;
  @extend .col-center;
  @extend .align-items;
  &-icon {
    font-size: 6rem;
    width: 6rem;
    height: 6rem;
    color: var(--warn) !important;
    &-wrapper {
      width: 6rem;
      height: 6rem;
    }
  }
  &-message {
    font-size: 1.5rem;
    color: var(--warn);
  }
}
.table {
  &-header {
    font-size: 1.1rem !important;
    font-weight: bold !important;
    @extend .no-select;
  }
  &-row {
    &:hover {
      background-color: var(--midground);
    }
  }
  &-cell {
    overflow: visible !important;
    padding: 0.1rem;
    @media only screen and (max-width: $tablet) {
      font-size: 0.9rem;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.8rem;
    }
  }
  &-paginator {
    border-top: 2px solid var(--accent);
    position: sticky;
    bottom: 0;
    z-index: 999;
    background-color: var(--uncolor);
    @extend .w-100;
    &-hide {
      visibility: hidden;
    }
    &-container {
      @extend .table-paginator;
      @extend .row-between;
      @extend .no-wrap;
      @extend .align-items;
    }
  }
}
.white-spinner circle {
  stroke: white !important; //override angular
}
input[type=number]::-webkit-inner-spin-button, //remove arrows from input type number
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* MAP PIN */
google-map div:focus-visible { //remove stupid outline on pin drag
  outline: none !important;
}
.map-pin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map-pin-icon {
  position: absolute;
  z-index: 1;
  top: 0.1em;
  font-family: 'Material Icons', sans-serif;
  font-size: 1.5em;
  line-height: 1.5em;
  color: #FFF;
}

/* DEBUG STYLES */
.BG {
  &R { background-color: indianred; }
  &O { background-color: lightsalmon; }
  &Y { background-color: khaki; }
  &G { background-color: lightseagreen; }
  &B { background-color: cornflowerblue; }
  &V { background-color: mediumpurple; }
}
.DEBUG {
  position: fixed;
  height: 5rem;
  width: 5rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 999;
}
