/* OVERRIDE ANGULAR STYLES */

//MAT TOOLBAR
.mat-toolbar, .mat-toolbar-row, .mat-toolbar-single-row { //make things fit better on mobile
  @media only screen and (max-width: $tablet) {
    padding: 0 8px !important;
  }
  @media only screen and (max-width: $mobile) {
    padding: 0 4px !important;
  }
}

//MAT BUTTON
.mat-mdc-unelevated-button.mat-primary { //why tf you gonna hard-code this to not be what it should be?
  --mdc-filled-button-label-text-color: var(--primary-contrast-500) !important;
}
.mat-mdc-unelevated-button.mat-accent { //why tf you gonna hard-code this to not be what it should be?
  --mdc-filled-button-label-text-color: var(--accent-contrast-500) !important;
}
.mat-mdc-unelevated-button.mat-warn { //why tf you gonna hard-code this to not be what it should be?
  --mdc-filled-button-label-text-color: var(--warn-contrast-500) !important;
}

//MAT FAB
.mdc-fab { //force text on mat fabs to be white
  --mat-mdc-fab-color: #FFF !important;
}

//MAT TAB
.mat-mdc-tab-body-content { //prevent horizontal scrollbar
  overflow-x: hidden !important;
}
.mat-mdc-tab-disabled { //hide tab when disabled
  display: none !important;
}
.mdc-tab { //allow tab width to get smaller on mobile
  flex-grow: 0 !important;
  min-width: 160px !important;
  padding: 0 1rem !important;
  @media only screen and (max-width: $mobile) {
    padding: 0 0.5rem !important;
    min-width: 0% !important;
    max-width: 100%;
  }
}
.mat-mdc-tab-label-container { //allow tab header to be scrollable
  overflow-x: scroll !important;
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { //hide the scroll bar
    display: none; /* Safari and Chrome */
  }
}
.mat-mdc-tab-header-pagination { //make < > smaller on mobile
  @media only screen and (max-width: $mobile) {
    min-width: 24px !important;
  }
}

//FORM MAT TAB
.form-tab-group .mat-mdc-tab { //divide tabs + make black
  border-left: 1px solid var(--midground);
  min-width: 0% !important;
  max-width: 100%;
  opacity: 1 !important;
  color: var(--color) !important;
  &:last-of-type {
    border-right: 1px solid var(--midground);
  }
}
.form-tab-group .mdc-tab-indicator--active { //highlight active tab
  background-color: var(--midground) !important;
}
.form-tab-group .mdc-tab-indicator--active .mdc-tab__text-label { //color the label text when active
  color: var(--color) !important;
}
.form-tab-group .mat-mdc-tab-header { //sticky + color + bottom border
  background-color: var(--uncolor);
  position: sticky;
  top: 0;
  z-index: 10;
  border-top: 2px solid var(--accent);
  border-bottom: 2px solid var(--accent) !important;
}
.form-tab-group .mat-mdc-tab-body-content { //hide overflow (for now)
  overflow-y: hidden !important;
}

//HOME MAT TAB
.home-tabs .mat-mdc-tab-header { //color tabs
  background-color: var(--accent);
}
.home-tabs .mat-mdc-tab { //font white, slight border, match sub-toolbar-height
  height: 3.5rem;
  border-left: 1px solid var(--primary);
  opacity: 1 !important;
  color: var(--accent-contrast-500) !important;
  &:last-of-type {
    border-right: 1px solid var(--primary);
  }
}
.home-tabs .mdc-tab-indicator--active { //highlight active tab
  background-color: var(--primary) !important;
  color: var(--primary-contrast-500) !important;
}
.home-tabs .mdc-tab__text-label {
  color: var(--accent-contrast-500) !important;
}
.home-tabs .mat-mdc-tab-body-wrapper { //mat tabs are so screwed
  height: 100%;
  max-height: 100%;
}

//MAT BUTTON TOGGLE
.mat-button-toggle-group { //why the fuck would you intentionally cut off text?!
  overflow: visible !important;
}
.mat-button-toggle-button {
  background-color: var(--uncolor) !important;
}
.mat-button-toggle-checked .mat-button-toggle-button {
  position: relative;
  z-index: 1;
  outline: 2px solid var(--accent) !important;
  border-radius: 3px;
}
.mat-button-toggle .mat-pseudo-checkbox { //too much padding
  margin-right: 0 !important;
}
.mat-button-toggle-label-content { //too much padding
  padding: 0 10px !important;
}

//MAT FAB
.mat-mdc-fab, .mat-mdc-mini-fab { //prevent shadow
  box-shadow: none !important;
}

//MAT ICON
.mat-mdc-icon { //prevent highlighting icons
  @extend .no-select;
}

//MAT CHIP
.mat-mdc-chip { //make chips smaller
  padding: 0 0.5rem !important;
}
.mat-mdc-chip .mat-mdc-chip-graphic, .mat-mdc-chip .mat-mdc-chip-action { //remove extra padding from icon/chip
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mat-mdc-chip {
  --mdc-chip-with-avatar-avatar-size: 1.1rem !important;
}
.mdc-evolution-chip-set .mdc-evolution-chip { //ffs
  margin-top: 0 !important;
  margin-bottom: 0.1rem !important;
}

//MAT FORM FIELD
.mat-mdc-form-field-subscript-wrapper { //hide the stupid bottom padding
  display: none;
}
.mat-mdc-form-field { //add some tiny top padding (the labels aren't great without it)
  margin-top: 0.1em;
}
.mat-mdc-text-field-wrapper { //how tf do they screw up padding so bad?
  padding-left: 0.7rem !important;
  padding-right: 0.7rem !important;
  background-color: var(--uncolor);
  border-radius: 4px !important;
}
.mat-mdc-form-field .mdc-floating-label { //bump up the label size
  font-size: 1.08em !important;
  overflow: visible;
}
.mat-mdc-form-field .mdc-floating-label .mat-mdc-form-field-required-marker { //bump up the required marker size
  font-size: 1.08em !important;
  color: var(--warn);
}
.mat-mdc-form-field-infix { //boy did they fuck up a lot
  //these must be adjusted in unison
  min-height: 52px !important; //56px
  padding-top: 18px !important; //16px
  padding-bottom: 8px !important; //16px
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above { //and the animation
  --mat-mdc-form-field-label-transform: translateY(-30.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.9)); //-34.75px, 0.75
}
.mdc-text-field--outlined .mdc-floating-label--float-above { //fix the scaling on the filled variant
  transform: translateY(-30.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.9)) !important;
}
.mdc-menu-surface.mat-mdc-select-panel { //remove excess padding from the inside of select panels
  padding: 0 !important;
}
.mdc-text-field { //remove angular's ugly performance hack that forces transparency
  will-change: unset !important;
  opacity: 1 !important;
}
.mat-mdc-form-field, .mat-mdc-floating-label { //fix the stupid line height
  line-height: unset !important;
}
.mat-mdc-form-field-error { //fix the obnoxiously large error messages
  font-size: 0.85rem !important;
}

//MAT INPUT
.mat-mdc-input-element {
  caret-color: auto !important;
}

//MAT DIALOG
.mat-mdc-dialog-container { //hide container overflow to use body only and remove excess padding on mobile
  overflow: hidden !important;
  @media only screen and (max-width: $mobile) {
    padding: 4px !important;
  }
}
.mat-mdc-dialog-title::before { //remove the stupid empty before element
  content: none !important;
  align-items: center !important;
}
.mat-mdc-dialog-title > span { //let this crap actually align properly
  display: flex !important;
  height: 48px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

//MAT TABLE
.mat-mdc-table { //force theme color
  background-color: var(--background) !important;
}
.mdc-data-table__header-cell { //force theme color
  background-color: var(--uncolor) !important;
  border-bottom: 2px solid var(--accent) !important;
}
th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type { //eliminate excess padding on mobile
  @media only screen and (max-width: $tablet) {
    padding-left: 4px;
  }
  @media only screen and (max-width: $mobile) {
    padding-left: 1px;
  }
}
th.mat-mdc-header-cell:last-of-type, td.mat-mdc-cell:last-of-type { //eliminate excess padding on mobile
  @media only screen and (max-width: $tablet) {
    padding-right: 4px;
  }
  @media only screen and (max-width: $mobile) {
    padding-right: 1px;
  }
}
.mdc-data-table__cell, .mdc-data-table__header-cell { //what's with the damn padding obsession?
  padding: 0;
}

//MAT PAGINATOR
.mat-mdc-paginator, .mat-mdc-paginator-container { //min the height better
  min-height: 48px;
  background-color: var(--uncolor);
}
.mat-mdc-paginator-page-size { //hide page size on mobile
  @media only screen and (max-width: $mobile) {
    display: none !important;
  }
}
.mat-mdc-paginator .mat-mdc-form-field-infix { //make the paginator not ridiculously oversized
  min-height: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.mat-mdc-paginator-range-label {
  margin: 0 0.5rem !important;
}
.mat-mdc-paginator-page-size {
  margin-right: 0 !important;
}
.mat-mdc-paginator-page-size-label {
  @media only screen and (max-width: $tablet) {
    display: none;
  }
}

//HOME MAT PAGINATOR
.home-paginator .mat-mdc-paginator-navigation-last { //hide the 'last page' button
  display: none !important;
}

//LISTING MAT DIALOG
.listing-dialog .mat-mdc-dialog-container {
  padding-bottom: 16px;
}

//FULLSCREEN MAT DIALOG
.fullscreen-dialog .mat-mdc-dialog-container {
  padding: 0 !important;
}
.fullscreen-dialog .mdc-dialog__surface {
  overflow-y: hidden !important;
}

//MAT SELECT (AND OPTION)
.mat-mdc-select-panel { //make it the app bg color
  background-color: var(--background);
}
.mat-mdc-select-panel .mat-mdc-form-field *, //make inputs inside pop more
.input-ms-overflow .mat-mdc-form-field * {
  background-color: var(--uncolor) !important;
}
.mdc-list-item__primary-text {
  width: 100%;
}

//MAT MULTI-SELECT
.hide-multi-select-checkbox .mat-pseudo-checkbox { //override showing the multi-select checkbox
  display: none !important;
}

//MAT PROGRESS BAR
.mat-mdc-progress-bar-buffer { //remove the stupid background color from the loadbar
  background: var(--background) !important;
}

//MAT SLIDE TOGGLE
.mat-mdc-slide-toggle-content { //allow the text to wrap because wtf angular
  white-space: normal !important;
  text-overflow: initial !important;
  line-height: 1rem !important;
}
body.dark-theme .mat-mdc-slide-toggle { //unfuck the colors
  --mdc-switch-selected-icon-color: var(--primary-contrast-500)
}

//MAT TOOLTIP
.mdc-tooltip, .mdc-tooltip *,
.mat-mdc-tooltip, .mat-mdc-tooltip *,
.mat-mdc-tooltip-panel, .mat-mdc-tooltip-panel * {
  // they hardcoded the max width inside the js, can't fix that
  font-size: 0.75rem;
}

//SUB TOOLBAR
.sub-toolbar .mat-mdc-text-field-wrapper {
  background-color: inherit;
}
.sub-toolbar .mdc-notched-outline > div {
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.sub-toolbar .mat-mdc-outlined-button:not(:disabled) {
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.sub-toolbar input, //color the text appropriately with the theme
.sub-toolbar input::placeholder,
.sub-toolbar mat-label,
.sub-toolbar .mat-mdc-select-value,
.sub-toolbar .mat-mdc-select-arrow {
  color: var(--accent-contrast-500) !important;
}
