@use '@angular/material' as mat; //https://material.angular.io/guide/theming
@use 'sass:map';

@include mat.core(); //ONLY INCLUDE ONCE

//Palettes
$theme-primary: (
  50 : var(--primary-50),
  100 : var(--primary-100),
  200 : var(--primary-200),
  300 : var(--primary-300),
  400 : var(--primary-400),
  500 : var(--primary-500),
  600 : var(--primary-600),
  700 : var(--primary-700),
  800 : var(--primary-800),
  900 : var(--primary-900),
  A100 : var(--primary-A100),
  A200 : var(--primary-A200),
  A400 : var(--primary-A400),
  A700 : var(--primary-A700),
  contrast: (
    50: var(--primary-contrast-50),
    100: var(--primary-contrast-100),
    200: var(--primary-contrast-200),
    300: var(--primary-contrast-300),
    400: var(--primary-contrast-400),
    500: var(--primary-contrast-500),
    600: var(--primary-contrast-600),
    700: var(--primary-contrast-700),
    800: var(--primary-contrast-800),
    900: var(--primary-contrast-900),
    A100: var(--primary-contrast-A100),
    A200: var(--primary-contrast-A200),
    A400: var(--primary-contrast-A400),
    A700: var(--primary-contrast-A700),
  )
);
$theme-accent: (
  50 : var(--accent-50),
  100 : var(--accent-100),
  200 : var(--accent-200),
  300 : var(--accent-300),
  400 : var(--accent-400),
  500 : var(--accent-500),
  600 : var(--accent-600),
  700 : var(--accent-700),
  800 : var(--accent-800),
  900 : var(--accent-900),
  A100 : var(--accent-A100),
  A200 : var(--accent-A200),
  A400 : var(--accent-A400),
  A700 : var(--accent-A700),
  contrast: (
    50: var(--accent-contrast-50),
    100: var(--accent-contrast-100),
    200: var(--accent-contrast-200),
    300: var(--accent-contrast-300),
    400: var(--accent-contrast-400),
    500: var(--accent-contrast-500),
    600: var(--accent-contrast-600),
    700: var(--accent-contrast-700),
    800: var(--accent-contrast-800),
    900: var(--accent-contrast-900),
    A100: var(--accent-contrast-A100),
    A200: var(--accent-contrast-A200),
    A400: var(--accent-contrast-A400),
    A700: var(--accent-contrast-A700),
  )
);
$theme-warn: (
  50 : var(--warn-50),
  100 : var(--warn-100),
  200 : var(--warn-200),
  300 : var(--warn-300),
  400 : var(--warn-400),
  500 : var(--warn-500),
  600 : var(--warn-600),
  700 : var(--warn-700),
  800 : var(--warn-800),
  900 : var(--warn-900),
  A100 : var(--warn-A100),
  A200 : var(--warn-A200),
  A400 : var(--warn-A400),
  A700 : var(--warn-A700),
  contrast: (
    50: var(--warn-contrast-50),
    100: var(--warn-contrast-100),
    200: var(--warn-contrast-200),
    300: var(--warn-contrast-300),
    400: var(--warn-contrast-400),
    500: var(--warn-contrast-500),
    600: var(--warn-contrast-600),
    700: var(--warn-contrast-700),
    800: var(--warn-contrast-800),
    900: var(--warn-contrast-900),
    A100: var(--warn-contrast-A100),
    A200: var(--warn-contrast-A200),
    A400: var(--warn-contrast-A400),
    A700: var(--warn-contrast-A700),
  )
);

//Palettes
$app-primary: mat.define-palette($theme-primary);
$app-accent: mat.define-palette($theme-accent);
$app-warn: mat.define-palette($theme-warn);

//Override Angular
$override: (
  'color': (
    'background': (
      // 'status-bar':
      // 'app-bar':
      'background': var(--background),
      // 'hover':
      // 'card':
      // 'dialog':
      // 'disabled-button:'
      // 'raised-button':
      // 'focused-button':
      // 'selected-button':
      // 'selected-disabled-button':
      // 'disabled-button-toggle':
      // 'unselected-chip':
      // 'disabled-list-option:
      // 'tooltip':
    ),
    // 'foreground': (
      // 'base':
      // 'divider':
      // 'dividers':
      // 'disabled':
      // 'disabled-button':
      // 'disabled-text':
      // 'elevation':
      // 'hint-text':
      // 'secondary-text':
      // 'icon':
      // 'icons':
      // 'text':
      // 'slider-min':
      // 'slider-off':
      // 'slider-off-active':
    // ),
  ),
);

//Themes
$light-theme: map.deep-merge(mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn
  )
)), $override);

$dark-theme: map.deep-merge(mat.define-dark-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn
  )
)), $override);

//Light Theme
@include mat.all-component-themes($light-theme);
body {
  --foreground: #{map.get(map.get($light-theme, foreground), foreground)};
  --midground: #{#C2C2C2};
  --color: #{#0D0D0D};
  --uncolor: #{#FFF};
  --primary: #{var(--primary-500)};
  --accent: #{var(--accent-500)};
  --warn: #{var(--warn-500)};
  --green: color-mix(in srgb, mediumaquamarine 34%, seagreen);
  --orange: color-mix(in srgb, orangered 17%, darkorange);
  --yellow: color-mix(in srgb, gold 42%, darkgoldenrod);
}

//Dark Theme
body.dark-theme {
  @include mat.all-component-colors($dark-theme);
  --foreground: #{map.get(map.get($dark-theme, foreground), foreground)};
  --midground: #{#171717};
  --color: #{#FFF};
  --uncolor: #{#0D0D0D};
  --primary: #{var(--primary-500)};
  --accent: #{var(--accent-500)};
  --warn: #{var(--warn-500)};
}

/* COLOR CLASSES */
.primary { color: var(--primary) !important; }
.accent { color: var(--accent) !important; }
.warn { color: var(--warn) !important; }
.green { color: var(--green) !important; }
.orange { color: var(--orange) !important; }
.yellow { color: var(--yellow) !important; }

.bg-green { background-color: var(--green) !important; }
.bg-orange { background-color: var(--orange) !important; }
.bg-yellow { background-color: var(--yellow) !important; }
