/* Media Vars */
$tablet: 900px; //half-screen 1080p - 1 (768 is 'official' breakpoint)
$mobile: 600px; //'official' breakpoint for phones

/* WRAPPERS/CONTAINERS */
.root {
  &-wrapper {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
  &-container {
    width: 100vw;
    height: calc(100vh - 4rem);
    overflow-y: auto;
    @media only screen and (max-width: $mobile) {
      height: calc(100vh - 3.5rem);
    }
  }
}
.wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  max-width: 100%;
  height: calc(100% - 4rem);
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (max-width: $mobile) {
    height: calc(100% - 3.5rem);
  }
  &-middle {
    margin: auto;
    width: 60%;
    max-width: max(60%, 50rem);
    min-width: min(99%, 50rem);
  }
}

/* SUB-TOOLBAR (main one is in _app.scss) */
.sub-toolbar {
  column-gap: 1rem;
  @extend .w-100;
  @extend .row-between;
  @extend .no-wrap;
  @media only screen and (max-width: $mobile) {
    column-gap: 0.2rem;
  }
  &-button {
    height: 3.25rem;
    width: 9rem;
    @media only screen and (max-width: $tablet) {
      width: 3.25rem;
      min-width: 3.25rem;
      padding: 0 0;
    }
    &-tooltip {
      @extend .row-center;
      @extend .align-items;
      @extend .no-wrap;
      @extend .h-100;
      @extend .w-100;
    }
    &>mat-icon { //override angular
      margin: 0 !important;
      font-size: 1.4rem !important;
      height: 1.4rem !important;
      width: 1.4rem !important;
    }
  }
  &-icon-button {
    height: 3.25rem;
    width: 3.25rem;
    min-width: 3.25rem;
    padding: 0 0;
    margin-top: 0.05rem;
    &>mat-icon { //override angular
      margin: 0 !important;
      font-size: 1.5rem !important;
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
  }
  &-input {
    font-size: 0.835rem;
    max-height: 100%;
  }
  &-select {
    @extend .sub-toolbar-input;
    width: 9rem;
    min-width: 6rem;
  }
}

/* LAYOUT CLASSES (FLEX) */
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &-between { @extend .row; justify-content: space-between }
  &-center { @extend .row; justify-content: center }
  &-around { @extend .row; justify-content: space-around }
  &-end { @extend .row; justify-content: end }
}
.col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  &-between { @extend .col; justify-content: space-between }
  &-center { @extend .col; justify-content: center }
}
.no-wrap {
  flex-wrap: nowrap;
}
.align-items {
  align-items: center;
}
.grow {
  flex-grow: 1;
}

/* LAYOUT CLASSES (GRID) */
.grid-col {
  display: grid;
  grid-row-gap: 0.5rem;
  @extend .w-100;
  &-1 {
    @extend .grid-col;
    grid-template-columns: minmax(0, 1fr);
  }
  &-2 {
    @extend .grid-col;
    grid-column-gap: 0.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media only screen and (max-width: $mobile) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
  &-3 {
    @extend .grid-col;
    grid-column-gap: 0.4rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @media only screen and (max-width: $tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (max-width: $mobile) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
  &-4 {
    @extend .grid-col;
    grid-column-gap: 0.3rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @media only screen and (max-width: $tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (max-width: $mobile) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
  &-5 {
    @extend .grid-col;
    grid-column-gap: 0.2rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    @media only screen and (max-width: $tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (max-width: $mobile) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
  &-6 {
    @extend .grid-col;
    grid-column-gap: 0.1rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    @media only screen and (max-width: $tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (max-width: $mobile) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
}
.grid-item {
  //&-1 {} is not necessary
  &-2 {
    grid-column-start: span 2;
    @media only screen and (max-width: $mobile) {
      grid-column-start: span 1;
    }
  }
  &-3 {
    grid-column-start: span 3;
    @media only screen and (max-width: $tablet) {
      grid-column-start: span 2;
    }
    @media only screen and (max-width: $mobile) {
      grid-column-start: span 1;
    }
  }
  &-4 {
    grid-column-start: span 4;
    @media only screen and (max-width: $tablet) {
      grid-column-start: span 2;
    }
    @media only screen and (max-width: $mobile) {
      grid-column-start: span 1;
    }
  }
  &-5 {
    grid-column-start: span 5;
    @media only screen and (max-width: $tablet) {
      grid-column-start: span 2;
    }
    @media only screen and (max-width: $mobile) {
      grid-column-start: span 1;
    }
  }
  &-6 {
    grid-column-start: span 6;
    @media only screen and (max-width: $tablet) {
      grid-column-start: span 2;
    }
    @media only screen and (max-width: $mobile) {
      grid-column-start: span 1;
    }
  }
}

/* LAYOUT CLASSES (MISC) */
.text-center {
  text-align: center;
}

/* MISC */
.loadbar {
  @extend .w-100;
  background: var(--uncolor);
  height: 4px;
  &-animation {
    @extend .w-100;
    height: 100%;
    background-color: var(--accent);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
    overflow-x: hidden;
  }
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.pointer {
  cursor: pointer;
}
.move {
  cursor: move;
}
.cursor {
  cursor: default;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.hide {
  display: none;
  &-tablet {
    @media only screen and (max-width: $tablet) {
      display: none;
    }
  }
  &-mobile {
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }
  &-input {
    position: relative;
    top: -0.5em;
    height: 0 !important;
    border: 0 !important;
    outline: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    @extend .w-100;
  }
}
.show {
  display: initial;
  &-tablet {
    display: none;
    @media only screen and (max-width: $tablet) {
      display: initial;
    }
  }
  &-mobile {
    display: none;
    @media only screen and (max-width: $mobile) {
      display: initial;
    }
  }
}

/* SIZING CLASSES (add as needed) */
.w {
  &-100 { width: 100%; max-width: 100%; }
  &-50 { width: 50%; }
  &-33 { width: 33%; }
  &-25 { width: 25%; }
  &-20 { width: 20%; }
}
.h-100 { height: 100%; }
